<template>
  <div>
    <div class="d-flex typography">
      <div class="container">
        <div class="card rounded-0 border-0 bg-white px-4">
          <div class="card-body px-0 pb-0">
            <div class="">
              <div class="row">
                <div class="col-8">
                  <div class="form-login mb-4">
                    <form v-if="currentBusiness">

                      <div class="form-group mb-2">
                        <label for="businessCategoryId" >Business Category</label>
                        <select class="form-control font-size-lg  form-control-lg bg-transparent py-0" :class="{ 'is-invalid': errors.businessCategoryId }"
                          v-model="currentBusiness.businessCategoryId" :disabled="isLoading ? '' : isLoading">
                          <option value="">All Categories</option>

                          <option v-for="item in businessCategories" :key="item.id" :value="item.id">
                            {{ item.name }}
                          </option>

                        </select>

                        <div class="invalid-feedback" v-if="errors.businessCategoryId">
                          {{ errors.businessCategoryId[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2">
                        <label for="businessName" >Business Name</label>
                        <input id="businessName" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.businessName }" v-model="currentBusiness.businessName"
                          placeholder="Enter business name" :disabled="isLoading ? '' : isLoading" />

                        <div class="invalid-feedback" v-if="errors.businessName">
                          {{ errors.businessName[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label for="businessEmail" >Business email</label>
                        <input id="businessEmail" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.businessEmail }" v-model="currentBusiness.businessEmail"
                          placeholder="Enter business email" :disabled="isLoading ? '' : isLoading" />

                        <div class="invalid-feedback" v-if="errors.businessEmail">
                          {{ errors.businessEmail[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-2">
                        <label for="website" >Website</label>
                        <input id="website" type="text" class="form-control font-size-lg  form-control-lg bg-transparent" :class="{ 'is-invalid': errors.website }"
                          v-model="currentBusiness.website" placeholder="ex: https://plectrumbusiness.com"
                          :disabled="isLoading ? '' : isLoading" />

                        <div class="invalid-feedback" v-if="errors.website">
                          {{ errors.website[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-2">
                        <label for="description" >Description</label>
                        <textarea id="description" class="form-control font-size-lg  form-control-lg bg-transparent" :class="{ 'is-invalid': errors.description }"
                          v-model="currentBusiness.description" height="300px" placeholder="Enter description"
                          :disabled="isLoading ? '' : isLoading"></textarea>

                        <div class="invalid-feedback" v-if="errors.description">
                          {{ errors.description[0] }}
                        </div>
                      </div>

                      <div class="form-group mt-6" v-if="errorMessage">
                        <MessageErrorFadeOut v-bind:message="errorMessage" v-bind:show="errorMessage?true:false" />
                      </div>

                      <button type="button" @click="updateBusiness" :disabled="isLoading ? '' : isLoading"
                        class="btn btn-primary btn-block btn-lg mb-7 font-weight-bold  font-size-md rounded-md">
                        <span v-if="!isLoading"> Update </span>
                        <span v-if="isLoading">
                          <i class="fas fa-circle-notch fa-spin"></i>
                          Updating...</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="col-4">
                  <div class="profile media row">
                    <div class="col-12" v-if="currentBusiness">

                        <div class="card image-box-style-card box-shadow-hover p-1 mb-3" style="border:1px solid #253644 !important">
                        <img class="p-1" v-bind:src="imagePreview" />
                      </div>
                      <div class="pl-2 upload-btn-wrapper col-12">

                        <button class="btn btn-primary font-size-sm text-center"
                          :disabled="isLogUploading ? '' : isLogUploading">

                          <span v-if="!isLogUploading"> Choose Logo </span>
                          <span v-if="isLogUploading">
                            <i class="fas fa-circle-notch fa-spin"></i>
                            Uploading...</span>
                        </button>
                        <input type="file" id="logo" @change="onFileChange"
                          :disabled="isLogUploading? '' : isLogUploading" />
                      </div>
                    </div>
                   <div class="col-12">
                     <div class="text-danger" v-if="errors.logo">
                      
                            {{errors.logo[0]}}
        
                            </div>
                   

                         <div class="text-danger" v-if="uploadError">
                      
                            {{uploadError}}
        
                            </div>
                   </div>

                  </div>
                  <div class="media-body d-flex flex-wrap"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
    import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut"
  export default {
    name: "EditMyBusiness",
    props: ["partnerId"],
    data: function () {
      return {
          uploadError:'',
        imagePreview: '/images/upload.gif',
        showPreview: false,
        isLogUploading: false,
        isLoading: false,
        errorMessage: null
      };
    },
      components: {
      MessageErrorFadeOut
    },
    computed: {
      ...mapGetters("auth", ["user"]),
      ...mapGetters("business", ['businessCategories', 'currentBusiness']),
      ...mapGetters(["errors", "isSuccess"]),
    },

    mounted() {
      this.getBusinessCategories();
      this.getPartnerBusiness(this.partnerId)
        .then(() => {
          this.imagePreview = this.currentBusiness ? this.currentBusiness.logo : '/images/upload.gif';
        });


      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);

    },

    methods: {
      ...mapActions("business", ["sendBusinessRequest", "getBusinessCategories", "getPartnerBusiness",
        "updateBusinessRequest", "changePartnerLogo"
      ]),

      updateBusiness: function () {
        this.isLoading = true;
        this.updateBusinessRequest(this.currentBusiness).then(() => {
          this.isLoading = false;
          if (this.errors && this.errors.message) {
            this.errorMessage = this.errors.message;
            this.$notify({
              group: "app",
              type: "error",
              title: "Something went wrong!",
              text: this.errors.message,
              duration: 10000,
              speed: 1000,
            });
          }
          if (this.isSuccess) {
            this.$notify({
              group: "app",
              type: "success",
              title: "Done",
              text: "Business details updated successfully",
              duration: 10000,
              speed: 1000,
            });

          }
        });
      },
      changeBusinessLogo: function () {

        let formData = new FormData();
        formData.append("logo", this.currentBusiness.logo);
        formData.append("partnerId", this.currentBusiness.partnerId);
        this.isLogUploading = true;
        this.changePartnerLogo(formData).then(() => {
          this.isLogUploading = false;
          if (this.isSuccess) {
            this.$notify({
              group: "app",
              type: "success",
              title: "Done",
              text: "Business Logo updated successfully",
              duration: 10000,
              speed: 1000,
            });
          }
        });
      },
      onFileChange(event) {
        /*
    Set the local file variable to what the user has selected.
    */
        this.currentBusiness.logo = event.target.files[0];

        /*
    Initialize a File Reader object
    */
        let reader = new FileReader();

        /*
    Add an event listener to the reader that when the file
    has been loaded, we flag the show preview as true and set the
    image to be what was read from the reader.
    */
        reader.addEventListener(
          "load",
          function () {
            this.showPreview = true;
            this.imagePreview = reader.result;
            this.changeBusinessLogo();
          }.bind(this),
          false
        );

        /*
    Check to see if the file is not empty.
    */
        if (this.currentBusiness.logo) {
        
          if (/\.(jpe?g|png|gif)$/i.test(this.currentBusiness.logo.name)) {
           
            reader.readAsDataURL(this.currentBusiness.logo);
           }else{
            this.uploadError="Only (jpg,jpeg,png,gif) image format allowed!";
          }
        }
      },
    },
  };
</script>